/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    rulers: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M1 0a1 1 0 00-1 1v14a1 1 0 001 1h5v-1H2v-1h4v-1H4v-1h2v-1H2v-1h4V9H4V8h2V7H2V6h4V2h1v4h1V4h1v2h1V2h1v4h1V4h1v2h1V2h1v4h1V1a1 1 0 00-1-1z"/>',
    },
});
